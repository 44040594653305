<template>
	<div>
		<el-row :gutter="10" type="flex" justify="space-between">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }">
				<hr style="border: 0.5px solid blue; margin-bottom: 20px" />
				<el-form ref="formref" :model="form" :inline="true" label-position="center" label-width="100px"
					:rules="rules1">
					<div class="formbox pb20" style="max-height:500px">
						<div class="div1 pb10">
							<el-form-item label="姓名" prop="name" class="mb5">
								<el-input v-model="form.name"></el-input>
							</el-form-item>
							<el-form-item label="角色" prop="rname" class="mb5">
								<el-select v-model="form.rname" placeholder="请选择" @change="roseChange">
									<el-option v-for="item in roleList" :key="item.id" :label="item.name"
										:value="item.id" />
								</el-select>
							</el-form-item>
							<el-form-item label="学号" prop="studentnum" class="mb5">
								<el-input v-if="dialogStatus == 'create'" v-model="form.studentnum"> </el-input>
								<el-input v-else v-model="form.studentnum" disabled> </el-input>
							</el-form-item>
							<el-form-item label="性别" prop="sex" class="mb5">
								<el-select v-model="form.sex" placeholder="请选择">
									<el-option v-for="item in sexList" :key="item.id" :label="item.label"
										:value="item.label" />
								</el-select>
							</el-form-item>
							<!-- <el-form-item label="密码" prop="password" class="mb5">
								<el-input type="password" v-model="form.password"></el-input>
							</el-form-item> -->
							<el-form-item label="宿舍楼" prop="dorid" class="mb5">
								<el-select v-model="form.dorid" placeholder="请选择宿舍楼">
									<el-option v-for="item in doridList" :key="item.id" :label="item.name"
										:value="item.id" />
								</el-select>
								<!-- <el-input v-model="form.company"></el-input> -->
							</el-form-item>
							<el-form-item label="宿舍号" prop="dormitorynum" class="mb5">
								<el-input v-model="form.dormitorynum"></el-input>
							</el-form-item>
							<el-form-item label="床位号" prop="teachage" class="mb5">
								<el-input v-model="form.teachage"></el-input>
							</el-form-item>
							<el-form-item label="身高" prop="height" class="mb5">
								<el-input v-model="form.height"></el-input>
							</el-form-item>
							<el-form-item label="体重" prop="weight" class="mb5">
								<el-input v-model="form.weight" placeholder="kg"></el-input>
							</el-form-item>
							<el-form-item label="健康状况" prop="health" class="mb5">
								<el-input v-model="form.health"></el-input>
							</el-form-item>
							<el-form-item label="毕业学校" prop="school" class="mb5">
								<el-input v-model="form.school"></el-input>
							</el-form-item>
							<el-form-item label="入学时间" prop="schoolday" class="mb5">
								<span class="demonstration"></span>
								<el-date-picker v-model="form.schoolday" type="date" placeholder="选择日期时间"
									format="yyyy-MM-dd" value-format="yyyy-MM-dd">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="父亲姓名" prop="fathername" class="mb5">
								<el-input v-model="form.fathername"></el-input>
							</el-form-item>
							<el-form-item label="母亲电话" prop="motnercellnum" class="mb5">
								<el-input v-model="form.motnercellnum"></el-input>
							</el-form-item>
							<el-form-item label="母亲微信ID" prop="motherid" class="mb5">
								<el-input v-model="form.motherid"></el-input>
							</el-form-item>
							<el-form-item label="母亲姓名" prop="mothername" class="mb5">
								<el-input v-model="form.mothername"></el-input>
							</el-form-item>
							<el-form-item label="母亲工作单位" prop="mothercompany" class="mb5">
								<el-input v-model="form.mothercompany"></el-input>
							</el-form-item>
							<el-form-item label="家庭住址" prop="addr" class="mb5">
								<el-input v-model="form.addr"></el-input>
							</el-form-item>
						</div>
						<div class="div2 pb10">
							<el-form-item label="照片" prop="photo" class="mb5">
								<img class="img1" :src="`${imgBaseUrl}${form.photo}${imgRightSub}`" alt=""
									@click="toSelectUserPhotoImg();" />
								<el-upload ref="myUploadUserPhoto" :action="imgBaseUrl + '/api/sysassessment/uploadAvator'"
									:data="{
										depId: this.form.depid,
										classId: this.form.classid,
										studentNum: this.form.studentnum,
										roleId: this.form.roleid,
									}" :headers="{ Authorization: this.token }" :limit="1" :show-file-list="false" multiple
									accept=".jpeg,.jpg,.png" :on-success="onSuccess" :disabled="!form.dname ||
										!form.cname ||
										!form.studentnum ||
										!form.rname
										">
									<el-button id="btnSelectUserPhoto" size="small" type="primary" :disabled="!form.dname ||
										!form.cname ||
										!form.studentnum ||
										!form.rname
										">上传照片</el-button>
								</el-upload>
								<p style="color: red">
									*必须优先填写系部、班级、角色和学号才可上传
								</p>
							</el-form-item>

							<el-form-item label="系部" prop="dname" class="mb5">
								<el-select v-model="form.dname" placeholder="请选择" @change="depidChanged">
									<el-option v-for="item in systemList" :key="item.id" :label="item.name"
										:value="item.id" />
								</el-select>
							</el-form-item>

							<el-form-item label="班级" prop="cname" class="mb5">
								<el-select v-model="form.cname" placeholder="请先选择系别" @change="classChange">
									<el-option v-for="item in cnameList" :key="item.id" :label="item.name"
										:value="item.id" />
								</el-select>
							</el-form-item>

							<el-form-item label="班号" prop="classnum" class="mb5">
								<el-input v-model="form.classnum" disabled></el-input>
							</el-form-item>
							<el-form-item label="手机号码" prop="username" class="mb5">
								<el-input v-model="form.username" maxlength="11"></el-input>
							</el-form-item>
							<el-form-item label="学生微信id" prop="wxid" class="mb5">
								<el-input v-model="form.wxid"></el-input>
							</el-form-item>
							<el-form-item label="政治面貌" prop="political" class="mb5">
								<el-input v-model="form.political"></el-input>
							</el-form-item>
							<el-form-item label="民族" prop="nation" class="mb5">
								<el-input v-model="form.nation"></el-input>
							</el-form-item>
							<el-form-item label="身份证" prop="pid" class="mb5">
								<el-input v-model="form.pid"></el-input>
							</el-form-item>
							<el-form-item label="出生日期" prop="birthday" class="mb5">
								<span class="demonstration"></span>
								<el-date-picker v-model="form.birthday" type="date" placeholder="选择日期时间" format="yyyy-MM-dd"
									value-format="yyyy-MM-dd">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="父亲工作单位" prop="fathercompany" class="mb5">
								<el-input v-model="form.fathercompany"></el-input>
							</el-form-item>
							<el-form-item label="父亲电话" prop="fathercellnum" class="mb5">
								<el-input v-model="form.fathercellnum"></el-input>
							</el-form-item>
							<el-form-item label="父亲微信ID" prop="fatherid" class="mb5">
								<el-input v-model="form.fatherid"></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>

				<div class="dialog-footer mt10 pt10 mb15 bt1_blue textCenter">
					<el-button @click="cancel()"> 取消 </el-button>
					<el-button type="primary" @click="dialogStatus == 'create' ? createData() : updateData()" :disabled="!form.dname ||
						!form.cname ||
						!form.studentnum ||
						!form.rname
						">
						确认
					</el-button>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import * as api from "@/api/users";
import {
	getHttpUrl
} from "@/utils/request";
import {
	getToken
} from "@/utils/token.js";
import {
	getNodeItemByID
} from "@/utils/mine.js";
export default {
	name: "addComponent",
	props: {
		dialogStatus: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			returnImgUrl: "",
			rules1: {
				name: [{
					required: true,
					trigger: "blur",
					message: "姓名4-16位字符必填"
				},],
				username: [{
					required: true,
					trigger: "blur",
					message: "手机号必填"
				}],
				password: [{
					required: true,
					trigger: "blur",
					message: "密码必填"
				}],
				rname: [{
					required: true,
					trigger: "blur",
					message: "角色必填"
				}],
				studentnum: [{
					required: true,
					trigger: "blur",
					message: "学号必填"
				}],
				dname: [{
					required: true,
					trigger: "blur",
					message: "系部必填"
				}],
				classnum: [{
					required: true,
					trigger: "blur",
					message: "班号必填"
				}],
				cname: [{
					required: true,
					trigger: "change",
					message: "班级必填"
				}],
				sex: [{
					required: true,
					trigger: "change",
					message: "性别必填"
				}],
			},
			// props: ["inputName", "dialog-status"],

			imgBaseUrl: getHttpUrl(),
			imgRightSub: "",

			readonly: true,
			typeList: [],
			infoForm: [],
			idis: "",
			value1: "",
			systemList: [],
			doridList: [],// 宿舍楼
			cnameList: [],
			options: [],
			// 初始化 图片
			form: {
				depid: "",
				cname: "",
				"photo": "/noHead.png",
			},
			unitList: [],
			groupList: [],
			sexList: [{
				id: 0,
				label: "女",
			},
			{
				id: 1,
				label: "男",
			},
			],
			roleList: [{
				id: 5,
				name: "学生会考评员"
			},
			{
				id: 7,
				name: "学生"
			},
			],
			infoFlag: true,
			userTypeList: [],
			token: "",
		};
	},
	watch: {
		studentData: {
			handler(val) {
				console.log(val);
			},
			deep: true,
		},
	},
	// vue 在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
	created() {
		this.load();
	},
	// vue 在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
	mounted() {
		this.token = getToken();
	},
	methods: {
		// 点击 网页图片，也能选 图片文件
		toSelectUserPhotoImg() {
			getNodeItemByID("btnSelectUserPhoto").click();
		},
		// 生成一个随机数
		randomNumImgRSub() {
			this.imgRightSub = "?t=" + Math.random().toString(36).slice(2);
		},
		roseChange(e) {
			this.form.roleid = e;
		},
		classChange(e) {
			let me = this;
			console.log("====== **** -------", e);
			me.cnameList.forEach(function (value) {
				if (value.id == e) {
					console.log("====== **** -------", value);
					me.form.cname = value.name;
					me.form.classnum = value.classnum;
					me.form.classid = value.id;
				}
			});
			console.log("====== **** -------", e);
		},
		// 上传成功
		onSuccess(response) {
			this.randomNumImgRSub();
			this.returnImgUrl = response.data;
			this.$set(this.form, "photo", response.data);
			// 在每一次上传请求结束后清除缓存，否则无法第二次选图
			this.$refs['myUploadUserPhoto'].clearFiles();
		},
		depidChanged(e) {
			let me = this;
			// 所选 系部ID
			me.form.depid = e;
			var systemList = me.systemList;
			for (let temp1 = 0; temp1 < systemList.length; temp1++) {
				if (systemList[temp1].id == me.form.depid) {
					// 所选 系部名称
					me.form.dname = systemList[temp1].name;
					break;
				}
			}
			// 刷新 系部 下的 班级 列表
			me.refreshClassList();
		},
		// 刷新班级列表数据
		refreshClassList() {
			let me = this;
			api.getCardArgumentDetail({ page: 1, pageSize: 10, depid: me.form.depid, }).then((res) => {
				let arr = res.data.records;
				let arrclass = [];
				for (let i = 0; i < arr.length; i++) {
					if (arr[i].depid === me.form.depid) {
						arrclass.push(arr[i]);
					}
				}
				me.cnameList = arrclass;
				console.log("====== **** -------", arrclass);
				// ------------- 改选班级（可能不存在了）-------------
				var classid = me.form.classid;
				console.log("====== **** -------", classid);
				if (classid == null || classid <= 0) {
					// 之前没有选班级
					if (me.cnameList.length > 0) {
						classid = me.cnameList[0].id
						me.form.cname = me.cnameList[0].name;
						me.form.classnum = me.cnameList[0].classnum;
						me.form.classid = me.cnameList[0].id;
					}
				} else {
					var ifFind = false;
					for (var temp = 0; temp < me.cnameList.length; temp++) {
						if (classid == me.cnameList[temp].id) {
							// 选中 上一次的 班级
							ifFind = true;
							me.form.cname = me.cnameList[temp].name;
							me.form.classnum = me.cnameList[temp].classnum;
							me.form.classid = me.cnameList[temp].id;
							break;
						}
					}
					if (ifFind == false) {
						if (me.cnameList.length > 0) {
							// 选中 第一个 班级
							me.form.cname = me.cnameList[0].name;
							me.form.classnum = me.cnameList[0].classnum;
							me.form.classid = me.cnameList[0].id;
						} else {
							// 没有 可选 班级
							me.form.cname = "";
							me.form.classnum = "";
							me.form.classid = -1;
						}
					}
				}
			});
		},
		load() {
			api.getCurrUserItem().then((res) => {
				this.idis = res.data.rname;
			});
			api.getSystemList({
				page: 1,
				pageSize: 1000,
			})
				.then((res) => {
					this.systemList = res.data.records;
					// 刷新 系部 下的 班级 列表
					this.refreshClassList();
				});

			// 查询宿舍楼列表信息接口
			api.selectDormitoryList({ page: 1, pageSize: 1000, }).then((res) => {
				this.doridList = res.data;
			});
		},
		// 关闭（调用底层容器的 方法 changeDialogFormVisible）
		cancel() {
			this.$emit("changeDialogFormVisible", false);
		},
		getTrue() {
			return true;
		},
		getFalse() {
			return false;
		},

		// ----------------------
		// 保存 新增的 数据
		createData() {
			let r = {};
			r.id = this.form.rname;
			let a = [];
			a.push(r);
			this.form.roles = a;
			this.form.status = 1;
			// identity值为1时，老师；identity值为0时，学生；
			this.form.identity = 0;
			this.form.roleid = this.form.rname;
			this.form.photo = this.returnImgUrl;

			this.form.password = "123456";

			api.transAddInfo(this.form).then(res => {
				if (res.status == 1) {
					this.$message({
						message: "添加学生成功",
						type: "success",
					});
					this.$emit("changeDialogFormVisible", false);
				} else {
					this.$message.error(res.msg);
				}
			});
		},

		// ----------------------
		// 保存 修改后的 数据
		updateData() {
			api.updateStuArgument(this.form.id, this.form).then(() => {
				this.$emit("changeDialogFormVisible", false);
			});
		},

		// ----------------------
		// 显示 新增|编辑 记录
		showData(record) {
			this.form = record;
			console.log("=========******** 这是数据信息==== ", this.form);
		},
	},
};
</script>
<style lang="scss" scoped>
.el-row {
	height: auto;
}

.el-col {
	width: 100%;
}

.el-dialog {
	height: 354px;
	width: 669px;
}

.img1 {
	width: auto;
	height: 164px;
	cursor: pointer;
}

.div2 {
	flex: 1;
	min-width: 340px;
}

.div1 {
	flex: 1;
	height: 188px;
	min-width: 340px;

	/deep/.el-form-item {
		height: 40px;
		min-width: 340px;
	}

	/deep/.el-form-item label {
		text-align: right;
	}
}

.formbox {
	display: flex;
	overflow: auto;
	max-height: 600px;
}
</style>

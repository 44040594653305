<template>
	<div class="app-container">
		<el-card>
			<div class="info mb10">
				<span>角色：</span>
				<el-select v-model="studentform.roleid" placeholder="请选择" clearable class="w180">
					<el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
				<span>院系：</span>
				<el-select v-model="studentform.depid" placeholder="请选择" @change="changeSystem" class="w180">
					<el-option v-for="item in systemList" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
				<span>班级：</span>
				<el-select v-model="studentform.classid" placeholder="请选择" clearable @change="changeClass" class="w180">
					<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</div>
			<div class="info">
				<span>姓名/学号：</span>
				<!-- 搜索 添加 -->
				<el-input placeholder="姓名或学号 关键字" class="w180" v-model="searchName" clearable></el-input>

				<!--
        <span>姓名：</span>
        <el-input v-model="studentform.name" placeholder="请输入姓名" class="w180" />
        <span>学号：</span>
        <el-input v-model="studentform.studentnum" placeholder="请输入学号" class="w180" />
        -->

				<div>
					<el-button type="primary" @click="goSearch(studentform)">搜索</el-button>
					<el-button type="primary" plain @click="addInfo" class="ml10">新增</el-button>
					<!-- 模板下载 -->
					<el-button type="primary" plain class="ml10" @click="downloadTemplate()">模板下载</el-button>
					<!-- 导入数据 -->
					<el-upload class="upload-data ml10" :action="imgBaseUrl + '/api/import/importStudentData'"
						:on-success="upload" :on-error="uploadError" contenteditable="" :show-file-list="false">
						<el-button type="primary" plain>导入</el-button>
					</el-upload>
					<!-- 批量更新头像 -->
					<el-upload class="upload-data" :action="imgBaseUrl + '/api/import/importUserAvatar'"
						:on-success="upload" contenteditable="" :show-file-list="false" accept=".zip,.rar,.7z">
						<el-button type="primary" plain>批量更新头像</el-button>
					</el-upload>
				</div>
			</div>

			<el-table class="mgt16" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
				element-loading-text="Loading" border fit highlight-current-row>
				<el-table-column label="序号" align="center" type="index" width="60px"></el-table-column>

				<el-table-column prop="name" align="left" label="姓名">
					<!-- 使用 scope 显示 v-html 内容 -->
					<template slot-scope="scope">
						<span v-html="scope.row.nameRed" v-bind:title="scope.row.id"></span>
					</template>
				</el-table-column>

				<el-table-column prop="studentnum" align="left" label="学号">
					<template slot-scope="scope">
						<span v-html="scope.row.studentnumRed" v-bind:title="scope.row.id"></span>
					</template>
				</el-table-column>

				<el-table-column prop="fractionnum" align="left" label="分数">
					<template slot-scope="scope">
						<span v-if="scope.row.fractionnum >= 60" class="tc_blue">{{ scope.row.fractionnum }}</span>
						<span v-else-if="scope.row.fractionnum < 60" class="tc_red">{{ scope.row.fractionnum }}</span>
						<span v-else>{{ scope.row.fractionnum }}</span> 分
					</template>
				</el-table-column>

				<el-table-column prop="rname" align="left" label="角色"></el-table-column>
				<el-table-column prop="sex" align="left" label="性别"></el-table-column>
				<el-table-column prop="dname" align="left" label="系部"></el-table-column>
				<el-table-column prop="cname" align="left" label="班级"></el-table-column>
				<el-table-column prop="classnum" align="left" label="班号"></el-table-column>
				<el-table-column id="clumn_9" property="address" label="操作" align="center" width="150px">
					<template slot-scope="scope">
						<div style="display: flex; justify-content: center">
							<el-button type="primary" size="small" @click="handleEdit(scope.row)">编辑</el-button>
							<el-button type="danger" size="small" @click="handleDelete(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<el-pagination class="mgt16" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="page" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total" />

			<!-- 新增|编辑 -->
			<el-dialog v-if="dialogFormVisible" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%"
				minWidth="600px" @close="cancelDialogFormShow()" center>
				<userAddComponent ref="dialogForm" :dialogStatus="dialogStatus"
					@changeDialogFormVisible="changeDialogFormVisible($event)" />
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
import * as api from "@/api/users";
import {
	getHttpUrl
} from "@/utils/request";
import userAddComponent from "@/views/add/userAddComponent.vue";
export default {
	components: {
		userAddComponent,
	},
	data() {
		return {
			imgBaseUrl: getHttpUrl(),

			studentform: {},
			searchName: "", // 姓名

			page: 1,
			pageSize: 10,
			form: {},
			formLabelWidth: "120px",
			Authorization: "",
			dialogFormVisible: false,
			dialogStatus: "",
			textMap: {
				update: "编辑",
				create: "新增",
				view: "详情",
			},

			listQuest: {
				page: 1,
				pageSize: 10,
			},
			roleList: [{
				id: 5,
				name: "学生会考评员"
			},
			{
				id: 7,
				name: "学生"
			},
			],
			systemList: [],
			classList: [],
			total: 0,
			options: [{
				label: "有",
				value: 0,
			},
			{
				label: "没有",
				value: 1,
			},
			],
			roles: [],
			tableData: [],
			input: "",
		};
	},
	created() {
		this.loadData();
		this.getData();
	},
	methods: {
		handleSizeChange(val) {
			this.pageSize = val;
			this.loadData();
		},
		handleCurrentChange(val) {
			this.page = val;
			this.loadData();
		},
		indexMethod(index) {
			return index * 1;
		},
		cancelDialogFormShow() {
			// alert("点击空白区域隐藏");
			this.$refs.editFormRef.resetFields();
			this.dialogFormVisible = false;
			this.loadData();
		},
		changeDialogFormVisible(data) {
			this.dialogFormVisible = data;
			this.loadData();
		},
		// 切换系部
		changeSystem(id) {
			api.getCardArgumentDetail({
				depid: id
			}).then((res) => {
				let classList = res.data.records.filter((item) => item.depid === id);
				this.classList = classList;
				this.classid = null;
				if (classList != null && classList.length > 0) {
					this.classid = classList[0].id;
				}
			});
		},
		// 切换班级
		changeClass(id) {
			this.classid = id;
		},
		handleDelete(row) {
			this.$confirm("确认删除？").then(() => {
				api.deleteStuArgument(row.id).then((res) => {
					if (res.status == 1) {
						this.loadData();
						this.$message.success(res.msg);
					} else {
						this.$message.success(res.msg);
					}
				});
			});
		},
		// 初始化数据
		getData() {
			let params = {
				page: 1,
				pageSize: 10,
			};
			// 院系接口
			api.getSystemList(params).then((res) => {
				this.systemList = res.data.records;
			});
		},
		loadData() {
			// 不传值查询所有
			api.getUserList({
				page: this.page,
				pageSize: this.pageSize,
				classid: "",
				identity: 0, // 类型：0为老师 1为学生
				searchName: this.searchName, // 姓名/学号
			})
				.then((res) => {
					if (res.status == 1) {
						// 关键字标红
						for (let i = 0; i < res.data.rows.length; i++) {
							res.data.rows[i].nameRed = res.data.rows[i].name;
							res.data.rows[i].studentnumRed = res.data.rows[i].studentnum;
							if (this.searchName.length > 0) {
								if (res.data.rows[i].name.indexOf(this.searchName) >= 0) {
									// 姓名标红
									var tempList1 = res.data.rows[i].name.split(this.searchName);
									res.data.rows[i].name = tempList1.join(
										'<span style="color:red;">' + this.searchName + "</span>"
									);
								}
								if (res.data.rows[i].studentnum.indexOf(this.searchName) >= 0) {
									// 工号标红
									var tempList2 = res.data.rows[i].studentnum.split(this.searchName);
									res.data.rows[i].studentnum = tempList2.join(
										'<span style="color:red;">' + this.searchName + "</span>"
									);
								}
							}
						}
						// 赋值
						this.tableData = res.data.rows;
						this.total = res.data.total;
					} else {
						this.$message.error(res.msg);
					}
				});
		},
		handleClose(done) {
			done();
		},
		// 编辑
		handleEdit(row) {
			this.dialogStatus = "update";
			this.dialogFormVisible = true;
			this.form = row;
			this.form.createDate = this.form.createDate ? this.$moment(this.form.createDate).format("YYYY-MM-DD") : "";
			this.form.encryptedData = this.form.encryptedData ? this.$moment(this.form.encryptedData).format(
				"YYYY-MM-DD") : "";
			this.$nextTick(() => {
				// 加载数据
				this.$refs.dialogForm.showData(row);
			});
		},
		// 传值搜索
		goSearch(form) {
			let query = {
				identity: 0, // 类型：0为老师 1为学生
				searchName: this.searchName, // 姓名/学号
				page: 1,
				pageSize: 10,
				name: form.name,
				classid: form.classid,
				studentnum: form.studentnum,
				roleid: form.role,
				depid: form.depid,
			};
			api.getUserList(query).then((res) => {
				if (res.status == 1) {
					// 关键字标红
					for (let i = 0; i < res.data.rows.length; i++) {
						res.data.rows[i].nameRed = res.data.rows[i].name;
						res.data.rows[i].studentnumRed = res.data.rows[i].studentnum;
						if (this.searchName.length > 0) {
							if (res.data.rows[i].name.indexOf(this.searchName) >= 0) {
								// 姓名标红
								var tempList1 = res.data.rows[i].name.split(this.searchName);
								res.data.rows[i].nameRed = tempList1.join(
									'<span style="color:red;">' + this.searchName + "</span>"
								);
							}
							if (res.data.rows[i].studentnum.indexOf(this.searchName) >= 0) {
								// 工号标红
								var tempList2 = res.data.rows[i].studentnum.split(this.searchName);
								res.data.rows[i].studentnumRed = tempList2.join(
									'<span style="color:red;">' + this.searchName + "</span>"
								);
							}
						}
					}
					// 赋值
					this.tableData = res.data.rows;
					this.total = res.data.total;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		addInfo() {
			this.dialogStatus = "create";
			this.dialogFormVisible = true;
			this.$nextTick(() => {
				this.loadData();
			});
		},
		// 上传成功
		upload(response) {
			if (response.status == -1) {
				// this.$message.error(response.msg);
				this.$confirm(response.msg, '提示', {
					confirmButtonText: '确定',
					type: 'error'
				}).then(() => { }).catch(() => { });
			} else {
				this.$message({ message: '数据导入成功', type: 'success' });
				this.loadData();
			}
		},

		/**
		 * 文件上传失败回调
		 */
		uploadError(err) {
			let me = this;
			console.log("err====== ", err);
			// me.$message.error("数据导入失败！");
			me.$confirm('数据导入失败！', '提示', {
				confirmButtonText: '确定',
				type: 'error'
			}).then(() => { }).catch(() => { });
		},

		/**
		 * 模板下载
		 */
		downloadTemplate() {
			window.location.href = this.imgBaseUrl + "/api/import/exportTemplate?rowType=1"
		}
	},
};
</script>
<style lang="scss" scoped>
/deep/ .el-container {
	width: 100%;
	height: 100% !important;
}

.info {
	display: flex;

	span {
		line-height: 41px;
	}
}

/deep/.el-dialog__title {
	display: flex;
	flex-direction: row;
}

/deep/.el-dialog__body {
	padding: 0px;
}

.gutter {
	width: 200px;
}

.upload-data {
	float: left;
}
</style>
